import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { IconComponent, PopoverModule } from '@sb/ui';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'clock-location-point',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent, CommonModule, PopoverModule],
  template: `
    <span [sbPopover]="mapPopover" [sbPopoverPosition]="'left'">
      <sb-icon *ngIf="!!lat && !!lon" [name]="'map-marker'"></sb-icon>
      <ng-template #mapPopover>
        <sb-popover [title]="title">
          <img [ngStyle]="{ 'width.px': width, 'height.px': height }" [src]="url" />
        </sb-popover>
      </ng-template>
    </span>
  `,
})
export class ClockLocationPointComponent implements OnChanges {
  @Input()
  public lat: string | undefined;
  @Input()
  public lon: string | undefined;
  @Input()
  public width = 288;
  @Input()
  public height = 160;
  @Input()
  public title = '';

  public url: string;

  public ngOnChanges(): void {
    if (!this.lat || !this.lon) {
      this.url = null;
    }

    const mapsKey = environment.mapsKey ? `&key=${environment.mapsKey}` : '';
    this.url = `https://maps.googleapis.com/maps/api/staticmap?size=${this.width}x${this.height}&zoom=15&sensor=false&markers=size:small|${this.lat},${this.lon}${mapsKey}`;
  }
}
