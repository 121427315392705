import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CalculateTimesheetTotalBreakTimePipe } from '@app/pipes/calculate-timesheet-total-break-time.pipe';
import { TimesheetModel } from '@app/reducers/orm/timesheet/timesheet.model';
import { DatePipe } from '@app/shared/sb-lib/calendar/pipes/date.pipe';
import { TranslateModule } from '@ngx-translate/core';

import { ClockLocationPointComponent } from './clock-location-point.component';

@Component({
  selector: 'timesheet-clock-info',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule, CommonModule, ClockLocationPointComponent, DatePipe, CalculateTimesheetTotalBreakTimePipe],
  templateUrl: './timesheet-clock-info.component.html',
})
export class TimesheetClockInfoComponent {
  @Input()
  public timesheet: TimesheetModel;
}
