<div class="modal-sidebar__header">
  {{ 'Clock information' | translate }}
  <ng-content></ng-content>
</div>
<div class="modal-sidebar__info">
  <div *ngIf="timesheet.clocked_in" class="modal-sidebar__element-container">
    <div class="modal-sidebar__element-row">
      <label class="modal-sidebar__element-row--label">{{ 'Clocked in' | translate }}:</label>
      {{ timesheet.clocked_in | date: 'HH:mm:ss' }}

      <ng-container *ngIf="timesheet.ClockedInOrigin">
        {{ 'via' | translate }} {{ timesheet.ClockedInOrigin }}
      </ng-container>

      <clock-location-point
        [lat]="timesheet.clocked_in_latitude"
        [lon]="timesheet.clocked_in_longitude"
        [title]="'Clocked in' | translate"
      ></clock-location-point>
    </div>
  </div>

  <ng-container *ngIf="timesheet.ClockBreak?.length > 0">
    <div *ngFor="let clockBreak of timesheet.ClockBreak" class="modal-sidebar__element-container">
      <div class="modal-sidebar__element-row">
        <label class="modal-sidebar__element-row--label">{{ 'Break' | translate }}:</label>
        {{ clockBreak.clocked_in | date: 'HH:mm:ss' }}
        <clock-location-point
          [lat]="timesheet.clocked_in_latitude"
          [lon]="timesheet.clocked_in_longitude"
          [title]="'Clocked in' | translate"
        ></clock-location-point>
        -
        {{ clockBreak.clocked_out | date: 'HH:mm:ss' }}
        <clock-location-point
          [lat]="clockBreak.clocked_out_latitude"
          [lon]="timesheet.clocked_out_longitude"
          [title]="'Break start' | translate"
        ></clock-location-point>
      </div>
    </div>

    <div class="modal-sidebar__element-container">
      <div class="modal-sidebar__element-row">
        <label class="modal-sidebar__element-row--label">{{ 'Total break' | translate }}:</label>
        {{ timesheet.clocked_break | calculateBreakTime }}
      </div>
    </div>
  </ng-container>

  <div *ngIf="timesheet.clocked_out" class="modal-sidebar__element-container">
    <div class="modal-sidebar__element-row">
      <label class="modal-sidebar__element-row--label">{{ 'Clocked out' | translate }}:</label>
      {{ timesheet.clocked_out | date: 'HH:mm:ss' }}

      <ng-container *ngIf="timesheet.ClockedOutOrigin">
        {{ 'via' | translate }} {{ timesheet.ClockedOutOrigin }}
      </ng-container>

      <clock-location-point
        [lat]="timesheet.clocked_out_latitude"
        [lon]="timesheet.clocked_out_longitude"
        [title]="'Clocked in' | translate"
      ></clock-location-point>
    </div>
  </div>
</div>
