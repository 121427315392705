import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculateBreakTime',
  pure: true,
  standalone: true,
})
export class CalculateTimesheetTotalBreakTimePipe implements PipeTransform {
  public transform(breakTimeInSeconds: string): string {
    if (!breakTimeInSeconds) {
      return '00:00';
    }
    const breakTimeInMinutes = parseFloat(breakTimeInSeconds) / 60;
    const roundedMinutes = Math.round(breakTimeInMinutes);
    if (roundedMinutes >= 1440) {
      return '24:00';
    }

    const hours = Math.floor(roundedMinutes / 60);
    const minutes = roundedMinutes % 60;

    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  }
}
